import Form from "./form";

const Workflow = {
    namespaced : true,
    modules : {
        form : Form
    }
}

export default Workflow
