import _ from 'lodash'

const Role = {

    bind : function(el, bind, vnode) {
        const roles = bind.value
        const vm = vnode.context

        const userRole = vm.$page.props.user.role

        const hasRole = _.find(roles, (role) => userRole == role);
        if (hasRole == undefined)
        {
            el.classList.add('hidden')
        }
    }

}

export default Role
