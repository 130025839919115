import _ from "underscore";

const Permission = {

    namespaced : true,
    state: {
        version: null,
        access: null
    },
    mutations: {
        parse(state, features) {
            /**
             * Access list
             * @type {*[]}
             */
            let accessList = {};


            if (!_.isEmpty(features.features))
            {
                /**
                 * Loop through features
                 */
                _.each(features.features, function (feature) {
                    /**
                     * Permission array holder
                     * @type {*[]}
                     */
                    let permissionArr = [];
                    /**
                     * Loop through permission
                     */
                    _.each(feature.permissions, function (permission) {
                        /**
                         * Permission key
                         * @type {String}
                         */
                        const permissionKey = _.keys(permission)[0];
                        /**
                         * Push to permission array holder
                         */
                        permissionArr.push(permissionKey)
                    })
                    const slug = feature.slug
                    /**
                     * Push to access list
                     * @type {*[]}
                     */
                    accessList[slug] = permissionArr
                })
                state.access = accessList
            }
            else
            {
                state.access  = null
            }
            state.version = features.version
        }
    },
    getters: {
        getAccess(state) {
            return state.access
        }
    },
    actions: {
        parse(context, features) {
            context.commit('parse', features)
        }
    }
}

export default Permission
