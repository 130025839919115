import Category from "./category"

const Product = {
    namespaced : true,
    modules : {
        category : Category
    }
}

export default Product
