import Rupiah from './Rupiah'
import Permission from './Permission'
import Role from './Role'

export default {

    install(Vue){

        Vue.directive('rupiah', Rupiah)
        Vue.directive('permission', Permission)
        Vue.directive('role', Role)

    }

}
