import axios from "axios"
import _ from 'underscore'

const Indicator = {
    namespaced : true,
    state: {
        pluginIndicator: [{
                name: 'Failed',
                color: 'red'
           }]
    },
    mutations: {
        setWaStatus(state, status) {
            state.pluginIndicator = status;
        }
    },

    actions: {
        async fetchWaStatus({ commit }, data) {
            return await axios.get(`/api/v2/wa/status`,
            {
                headers : {
                    'Authorization' : 'Bearer ' + data.api_token,
                }
            })
            .then(function(response){
                commit("setWaStatus", response.data);
            })
            .catch(err => console.error(err));
        }
    },
    getters: {
        wa_status(state) {
            return state.pluginIndicator;
        }
    }
}

export default Indicator
