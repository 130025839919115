import axios from "axios"
import _ from 'underscore'

const Category = {
    namespaced : true,
    state : () => {
        return {
            categories : []
        }
    },
    actions : {
        async getCategories({ commit }) {
            try {
                const response = await axios.get(this._vm.$route('api.v2.crm.category'))
                const { status, data } = response
                if (status == 200) {
                    commit('setCategories', data)
                } else throw new Error("")
            } catch (err) {
                console.log(err)
                this._vm.$notify({
                    type: "error",
                    title: "Kesalahan",
                    text: "Gagal mengambil data"
                })
            }
        }
    },
    mutations : {
        setCategories(state, payload) {
            state.categories = payload
        }
    },
    getters : {
        getCategories : state => state.categories
    }
}

export default Category
