import currency from "currency.js"

const Rupiah = (value) => currency(value, {
    symbol: 'Rp. ',
    decimal: ',',
    separator: '.'
}).format()

export default {

    bind : function(el, binding) {

    },
    componentUpdated: function(value, oldValue) {

    }

}
