import Permission from "@/stores/user/permission";

console.log(Permission)

const User = {
    namespaced : true,
    modules : {
        permission : Permission
    }
}

export default User
