import _ from 'underscore'

function notifyError(vm) {
    vm.$notify({
        type : 'warning',
        title : "Peringatan",
        text : "Permission error"
    })
}

function enableMenu(el)
{
    const anchor = el.querySelector('a')
    const span = el.querySelector('span')
    const i = el.querySelector('i')
    const lockedIcon = el.querySelector('.locked-feature')

    if (anchor !== null)
    {
        //anchor.classList.remove('cursor-not-allowed')
    }
    if (i !== null) {
    }
    if (span !== null) {
    }
    if (lockedIcon !== null)
    {
        // bug: lock icon not removed
        lockedIcon.remove()
    }

}

function disableMenu(el)
{
    const anchor = el.querySelector('a')
    const span = el.querySelector('span')
    const i = el.querySelector('i')

    if (i !== null)
    {
        let lockIcon  = document.createElement('i')
        lockIcon.className = "locked-feature fa-solid fa-fw fa-lock absolute right-2 mr-1"
        if (span !== null) {
            span.after(lockIcon)
        }
    }
    if (span !== null)
    {
    }

}

export default {

    bind(el, bind, vnode) {

        const i = el.querySelector('i')
        if (i !== null)
        {

        }
        el.classList.add('permission-anchor')

    },
    componentUpdated(el, bind, vnode)
    {
        const context = vnode.context
        const store = context.$store
        const version = store.state.user.permission.version
        if (version === 2)
        {
            const access = store.state.user.permission.access

            const slug = bind.arg;
            const permission = bind.value;
            const vm = vnode.context

            let userAccess = false
            if (access !== null)
            {
                _.each(access, function (data) {
                    const status = _.contains(data, permission);
                    if (status === true) {
                        userAccess = status
                    }
                })

                if (userAccess === false)
                {
                    disableMenu(el)
                } else {
                    enableMenu(el)
                }
            }
            else
            {
                disableMenu(el)
            }
        }
        else if (version === 1)
        {
            enableMenu(el)
        }
    }
}
