import Product from "./product/index"
import Workflow from "./workflow/index"

const Crm = {
    namespaced : true,
    modules : {
        product : Product,
        workflow : Workflow
    }
}

export default Crm
