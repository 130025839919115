import Crm from './crm'
import Notification from './notification'
import Indicator from "./user/indicator"
import User from './user'

const store = {
    namespaced : true,
    modules : {
        crm : Crm,
        notification : Notification,
        indicator : Indicator,
        user : User
    }
}

export default store
