require("./argon/vue-argon");

import Vue from "vue";
import Vuex from 'vuex';
import { createInertiaApp } from "@inertiajs/inertia-vue";
import { InertiaProgress } from "@inertiajs/progress";
import DataTable from 'laravel-vue-datatable';
import Notifications from 'vue-notification'
import VuejsDialog from 'vuejs-dialog';
import VueSimpleSuggest from 'vue-simple-suggest'
import Multiselect from 'vue-multiselect'
import PortalVue from 'portal-vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import DateRangePicker from 'vue2-daterange-picker'
import Skeleton from 'vue-loading-skeleton';
import store from '@/stores/index'
import { EmojiPickerPlugin } from 'vue-emoji-picker'
import Directives from './directives'
import VueYouTubeEmbed from 'vue-youtube-embed'
import Popover from 'vue-js-popover'
import VPopover from 'vue-js-popover'
import VueTimepicker from 'vue2-timepicker'

import "vue-simple-suggest/dist/styles.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

// import font
import "@fontsource/open-sans/400.css";

InertiaProgress.init();

// fix ziggy route
const route = window.route;
// initialize ziggy's route
Vue.prototype.$route = route;

Vue.use(Vuex);
Vue.use(DataTable);
Vue.use(Notifications);
Vue.use(VuejsDialog, {
    html: true,
    okText: 'Ya',
    cancelText: 'Batal',
    animation: 'bounce'
});
Vue.use(VueSimpleSuggest);
Vue.use(PortalVue);
Vue.use(PerfectScrollbar);
Vue.use(Skeleton)
Vue.use(EmojiPickerPlugin)
Vue.use(Directives)
Vue.use(VueYouTubeEmbed)
Vue.use(Popover)
Vue.use(VPopover, { tooltip: true })

Vue.component('date-range-picker', DateRangePicker);
Vue.component('vue-simple-suggest', VueSimpleSuggest)
Vue.component('multiselect', Multiselect)
Vue.component('vue-timepicker', VueTimepicker);


createInertiaApp({
    resolve: name => import(`./Pages/${name}`),
    setup({ el, App, props }) {
        const vuexStore = new Vuex.Store(store)
        const app = new Vue({
            store : vuexStore,
            render: h => h(App, props)
        })
        app.$mount(el);
    }
});
