const Form = {
    namespaced : true,
    state : () => {
        return {
            groups : [],
            tags : [],
            // base form
            form : {
                name : '',
                type : "pipeline",
                description : '',
                status : '',
                actions : [],
                conditions : []
            }
        }
    },
    mutations : {
        reset(state) {
            state.type = 'pipeline'
        },
        setType(state, payload) {
            state.type = payload
        },
        setTags(state, payload) {
            state.tags = payload
        },
        setGroups(state, payload) {
            state.groups = payload
        }
    },
    actions : {

    },
    getters : {

    }
}

export default Form
